const { default: axios } = require('axios');

require('./bootstrap');

require('alpinejs');

//TOUT REDECOMMENT APRES TOUTE LES LIGNE SONT BONNES
// const messages_el = document.getElementById("messages");
// const username_input = document.getElementById("username");
// const message_input = document.getElementById("message_input");
// const message_form = document.getElementById("message_form");

// message_form.addEventListener('submit',function (e){
//     e.preventDefault();

//     let has_errors = false;

//     if(username_input.value == ""){
//         alert("Please enter a Username");
//         has_errors = true;
//     }

//     if(message_input.value == ""){
//         alert("Please enter a Message");
//         has_errors = true;
//     }

//     if(has_errors){
//         return;

//     }

//     const options = {
//         method: 'post',
//         url: '/chatmessage',
//         data: {
//             username: username_input.value,
//             message: message_input.value
//         }

//     }

//     axios(options)

// });

// Pusher.logToConsole = true;



// Echo.channel('channel-perso')
//     .listen('ChatMessage', (e)=>{
//         console.log("je recoit");
//         messages_el.innerHTML += '<div class="message"><strong>' + e.username + ':<strong>' + e.message + '</div>';
//     })


